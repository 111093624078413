@import url("https://use.typekit.net/vxy2fer.css");
 
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200..900&display=swap");
 
 .gallery {
	display: flex;
	margin-left: 12vw;
	padding-right: 12vw;
}

 .gallery__item1 {
	margin: 0 3vw;
	display: grid;
	grid-template-areas: '... ...' 
						 '... gallery-image'
						 '... ...' ;
						 grid-template-columns: 8rem 21vmax;
						 grid-template-rows: 6rem 28vmax 3rem;
}

 .gallery__item:nth-child(even) {
	padding-top: 10vh;
}

.demo1 .gallery__item-img {
	grid-area: gallery-image;
	width: 30vw;
	height: 40vh;
	overflow: hidden;
	position: relative;
	transform-origin: -99%;
	margin: 0px 0px 0px 0px;
}

 .gallery__item-imginner {
	background-size: cover;
    background-position: 50% 0;
    width: 100%;
    height: 100%;
}

 .gallery__item-caption {
	grid-area: 1 / 1 / 4 / 3;
	display: grid;
	grid-template-areas: 'gallery-number gallery-title' 
						 'gallery-link ...'
						 'gallery-link gallery-tags' ;
	grid-template-columns: 8rem auto;
	grid-template-rows: 8rem auto 3rem;
	/* font-family: "Courier Prime"; */


}

 .gallery__item-number {
	grid-area: gallery-number;
	font-size: 6rem;
	font-size: clamp(2.5rem,9vw,6.5rem);
	justify-self: end;
	padding-right: 2rem;
	color: #d9d0be;
}

 .demo1 .gallery__item-title {
	grid-area: gallery-title;
	margin: 0;
	font-size: 42px;
	/* font-size: clamp(2rem,5vw,4rem); */
	/* color: black; */
	font-family: "Courier Prime";
	align-self: center;
	color: black;

	
}

 .gallery__item-number,
 
 .gallery__text {
	/* font-family: moret, serif; */
	font-weight: 800;
	font-style: italic;
	align-self: center;
	
}


.demo1 .gallery__item-link1 {
	/* background: #2858d7; */
	/* color:  #fff; */
	/* margin: 0px 0px 160px 60px !important; */
	font-size: 20px;
	font-family: "Courier Prime";
	width: 100px;
	height: 100px;
	border-radius: 50%;



	/* font-size: 1.5rem; */
	text-decoration: none;
	cursor: pointer;

	grid-area: gallery-link;
	align-self: end;
	display: flex;
	position: relative;
	padding: 1.5rem;
	overflow: hidden;
	justify-content: center;
	align-items: center;

	transition: 0.2s transform ease-in-out, 0.6s background-color;
	will-change: transform;
	z-index: 0;

}
 /* .demo1 .gallery__item-link1:hover {
	color:  #fff;
	background-color: #d4b77d;

} */
 .demo1 .gallery__item-link1::after {
	background-color: #d4b77d;
}


 .gallery__item-tags1 {
	grid-area: gallery-tags;
	justify-self: end;
	font-size: 20px;
	display: grid;
	grid-auto-flow: column;
	grid-gap: 1rem;
	cursor: pointer;
	margin: -130px 0px 0px 150px;
	cursor: pointer;
	font-family: "Courier Prime";
	font-weight: lighter;
	color: black;

}

 .gallery__text {
	font-size: 20vw;
	line-height: 0.8;
	margin: 0 10vw 0 14vw;
	text-transform: lowercase;
	color: #2c2724;
	color: transparent;
	-webkit-text-stroke: 1px #978c77;
	/* text-stroke: 1px #978c77; */
	-webkit-text-fill-color: transparent;
	/* text-fill-color: transparent; */
}

 .gallery__text-inner {
	display: block;
}