/* body:has(.demo3) {
	--color-text: #000;
	--color-bg: #dedede;
	--color-link: #828da1;
	--color-link-hover: #cc0000;
	--color-gallery-title: #d9d0be;
	--color-gallery-number: #000;
	--color-gallery-link: #fff;
	--color-gallery-link-bg: #cc0000;
	--color-gallery-link-hover: #0e0e0d;
	--color-gallery-link-bg-hover: #d9d0be;
	--color-frame-subtitle: #727272;
	--cursor-stroke: none;
	--cursor-fill: #cc0000;
	--cursor-stroke-width: 1px;
} */

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200..900&display=swap");

.demo3 .frame__header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-transform: none;
	padding: 2rem 0;
}

.demo3 .frame__header-title {
	font-family: moret, serif;
	font-weight: 700;
	font-size: 6rem;
	font-size: clamp(2rem,8vw,7rem);
	margin: 0;
}

.demo3 .frame__header-subtitle {
	font-size: 1.75rem;
	margin: 0.5rem 0;
	color: var(--color-frame-subtitle);
}

.demo3 .contentsDemo3 .gallery3 {
	display: flex;
	/* padding-right: calc(50vw - 7.5vmax - 1rem);
	padding-left: calc(50vw - 7.5vmax - 1rem); */
	/* gap: 95px; */
	padding-left: 65rem !important;
	padding-right: 65rem !important;
	gap: 20px;

}

.demo3 .gallery__item3 {
	margin: 0;
	display: grid;
	grid-template-areas: '... ...' 
						 'gallery-image gallery-image'
						 'gallery-image gallery-image' ;
	grid-template-columns: 20rem 25vh;
	grid-template-rows: 8rem 45vh 3rem;
}

.demo3 .gallery__item-img {
	grid-area: gallery-image;
	/* width: 40vw; */
	width: 30vw;

	height: 40vh;
	overflow: hidden;
	position: relative;
	transform-origin: 50% 100%;
	will-change: transform;
	cursor: pointer;
	z-index: 2;
}


/* Move figure slightly down */
.gallery__item--down {
    /* transform: translateY(90px);  */

    transform: translateY(20px); /* Adjust as needed */
}

/* Move figure slightly up */
.gallery__item--up {
    transform: translateY(-50px); /* Adjust as needed */
}


.demo3 .gallery__item-imginner {
	/* background-size: cover; */
    /* background-position: 50% 25%; */
	/* width: calc(100% + 10vw); */
	/* margin-left: 1vw; */
    /* height: 100%; */
    will-change: transform;
	background-size: cover;
	background-position: 50% 25%;

    /* width: 100%; */
    height: 100%;
}

.demo3 .gallery__item-caption {
	grid-area: 1 / 1 / 4 / 3;
	display: grid;
	grid-template-areas: 'gallery-number gallery-number' 
						 'gallery-link ...'
						 'gallery-link gallery-tags' ;
	grid-template-columns: 8rem auto;
	grid-template-rows: 4rem auto 3rem;
	position: relative;
	z-index: 1;
}

.demo3 .gallery__item-number {
	grid-area: gallery-number;
	font-size: 10vw;
	justify-self: center;
	color: #000;
	cursor: pointer;
	color: transparent;
	-webkit-text-stroke: 1px #645c5b;
	text-stroke: 1px #645c5b;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
}

.demo3 .gallery__item-caption .gallery__item-title3 {
	grid-area: gallery-title;
	margin: 0;
	font-size: 4rem;
	font-size: clamp(2rem,5vw,4rem);
	color: #d9d0be;
	display: none;
	align-self: center;

}

.demo3 .gallery__item-number,

.demo3 .gallery__text {
	/* font-family: moret, serif; */
	font-weight: 700;
	align-self: center;
	font-family: "Courier Prime";
    font-size: 42px;
}

.demo3 .gallery__item-link {
	grid-area: gallery-link;
	align-self: end;
	font-size: 1.5rem;
	background: #cc0000;
	color: #fff;
	text-decoration: underline;
	width: 120px;
	height: 120px;
	border-radius: 50%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	display: none;
}

.demo3 .gallery__item-link:focus,
.demo3 .gallery__item-link:hover {
	background: #d9d0be;
	color: #0e0e0d;
	text-decoration: none;
}

.demo3 .gallery__item-tags {
	grid-area: gallery-tags;
	justify-self: end;
	font-size: 1.5rem;
	display: grid;
	grid-auto-flow: column;
	grid-gap: 1rem;
	cursor: pointer;
	display: none;
}

.demo3 .gallery__text {
	font-size: 20vw;
	line-height: 0.8;
	text-transform: lowercase;
	color: transparent;
	-webkit-text-stroke: 1px #645c5b;
	text-stroke: 1px #645c5b;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	position: absolute;
	right: 0;
	font-family: "Courier Prime";

}

.demo3 .gallery__text:first-child {
	left: -1vw;
	right: auto;
}

.demo3 .gallery__text span {
	display: block;
}

@media screen and (min-width: 53em) {
	.demo3 .frame {
		grid-template-areas: 'title demos demos links'
							'header header header header';

	}
	.demo3 .frame__header {
		grid-area: header;
		text-align: center;
		height: 40vh;
	}
	.demo3 .content {
	    height: 100vh;
	    justify-content: flex-end;
	}
}
