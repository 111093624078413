/* body:has(.demo4) {
	--color-text: #4a525e;
	--color-bg: #121a27;
	--color-link: #b2366f;
	--color-link-hover: #fff;
	--color-gallery-title: #4a525e;
	--color-gallery-number: #54d7e4;
	--color-gallery-link: #fff;
	--color-gallery-link-bg: #e95499;
	--color-gallery-link-hover: #e95499;
	--color-gallery-link-bg-hover: #fff;
	--color-gallery-text: #e95499;
	--cursor-stroke: none;
	--cursor-fill: #79e2e6;
	--cursor-stroke-width: 1px;
} */

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200..900&display=swap");



.demo4 .gallery4 {
	display: flex;
	margin-left: 0vw;
	padding-right: 12vw;
	
	
}

.demo4 .gallery__item {
	margin: 1.5vw 0px 0vw 1.2vw;
	/* margin: 0 3vw; */

	display: grid;
	grid-template-areas: '... ...' 
						 '... gallery-image'
						 '... ...' ;
	grid-template-columns: 8rem 21vmax;
	grid-template-rows: 4rem 28vmax 3rem;
}

.demo4 .gallery__item-img {
	grid-area: gallery-image;
	/* width: 40vw;*/
	width: 30vw;
	height: 40vh;
	overflow: hidden;
	position: relative;
	transform-origin: -80% 100%;
}

.demo4 .gallery__item-imginner {
	/* background-size: cover; */
    background-position: 50% 0;
    width: 100%;
    height: 100%;
}

.demo4 .gallery__item-caption {
	grid-area: 1 / 1 / 4 / 3;
	display: grid;
	grid-template-areas: 'gallery-number gallery-title' 
						 'gallery-link ...'
						 'gallery-link gallery-tags' ;
	grid-template-columns: 8rem auto;
	grid-template-rows: 8rem auto 3rem;

}

.demo4 .gallery__item-number {
	grid-area: gallery-number;
	font-size: 6rem;
	font-size: clamp(2.5rem,9vw,6.5rem);
	justify-self: end;
	padding-right: 2rem;
	color: #54d7e4;
}

.demo4 .gallery__item-title4 {
	grid-area: gallery-title;
	margin: 0;
	font-size: 42px;
	/* font-size: clamp(3rem,3vw,3rem); */
	color: #4a525e;
	align-self: center;

}

.demo4 .gallery__item-number,
.demo4 .gallery__item-title4,
.demo4 .gallery__text {
	font-family: "Courier Prime";
	font-weight: 800;
	font-style: italic;
	align-self: center;
}

.demo4 .gallery__item-link4 {
	/* background: #e95499; */
	/* color: #fff; */
	/* margin: 0px 0px 150px 120px; */
	font-size: 20px;
	font-family: "Courier Prime";
	width: 100px;
	height: 100px;
	border-radius: 50%;



	/* font-size: 1.5rem; */
	text-decoration: underline;
	cursor: pointer;

	grid-area: gallery-link;
	align-self: end;
	display: flex;
	position: relative;
	padding: 1.5rem;
	overflow: hidden;
	justify-content: center;
	align-items: center;

	transition: 0.2s transform ease-in-out, 0.6s background-color;
	will-change: transform;
	z-index: 0;

}
/* .demo4 .gallery__item-link4:hover {
	color: #e95499;
	background-color:  #fff;

}
.demo4 .gallery__item-link4::after {
	background-color:  #fff;
} */

.demo4 .gallery__item-tags4 {
	grid-area: gallery-tags;
	justify-self: end;
	font-size: 20px;
	display: grid;
	grid-auto-flow: column;
	grid-gap: 1rem;
	cursor: pointer;
	margin: -130px -60px 0px 0px;

	font-family: "Courier Prime";
	font-weight: lighter;
}

.demo4 .gallery__text {
	font-size: 20vw;
	line-height: 0.8;
	margin: 0 10vw 0 14vw;
	text-transform: lowercase;
	color: transparent;
	-webkit-text-stroke: 1px #e95499;
	text-stroke: 1px #e95499;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	
}

.demo4 .gallery__text-inner {
	display: block;
}