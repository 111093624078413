/* 
body:has(.demo5) {
	--color-text: #fff;
	--color-bg: #131212;
	--color-link: #d75828;
	--color-link-hover: #fff;
	--color-gallery-title: #463832;
	--color-gallery-number: #ffffff;
	--color-gallery-link: #fff;
	--color-gallery-link-bg: #d75828;
	--color-gallery-link-hover: #fff;
	--color-gallery-link-bg-hover: #d4b77d;
	color: var(--color-text);
	background-color: var(--color-bg);
	--cursor-stroke: none;
	--cursor-fill: #ffffff;
	--cursor-stroke-width: 1px;
} */

.demo5 .gallery5 {
	display: flex;
	margin-left: 12vw;
	padding-right: 12vw;
	
}

.demo5 .gallery__item {
	/* margin: 0 3vw; */
	display: grid;
	grid-template-areas: '... ...' 
						 '... gallery-image'
						 '... ...' ;
	grid-template-columns: 6rem 21vmax;
	grid-template-rows: 6rem 28vmax 3rem;
	will-change: transform;
	
}

.demo5 .gallery__item:nth-child(even) {
	padding-top: 10vh;
}

.demo5 .gallery__item-img {
	grid-area: gallery-image;
	/* width: 40vw; */
	width: 25vw;

	height: 40vh;
	overflow: hidden;
	position: relative;
	will-change: transform;
}

.demo5 .gallery__item-imginner {
	background-size: cover;
    background-position: 50% 0;
    width: 100%;
    /* height:100%; */
	/* margin-top: -7vh; */
	will-change: transform;
}

.demo5 .gallery__item-caption {
	grid-area: 1 / 1 / 4 / 3;
	display: grid;
	grid-template-areas: 'gallery-number gallery-title' 
						 'gallery-link ...'
						 'gallery-link gallery-tags' ;
	grid-template-columns: 8rem auto;
	grid-template-rows: 8rem auto 3rem;

}

.demo5 .gallery__item-number {
	grid-area: gallery-number;
	font-size: 6rem;
	font-size: clamp(2.5rem,9vw,6.5rem);
	justify-self: end;
	padding-right: 2rem;
	color: #ffffff;
}

.demo5 .gallery__item-title {
	grid-area: gallery-title;
	margin: 0;
	font-size: 42px;

	font-family: "Courier Prime";
	align-self: center;
	color: black;
}

.demo5 .gallery__item-number,

.demo5 .gallery__text {
	font-family: "Courier Prime";

	font-weight: 800;
	font-style: italic;
	align-self: center;
}

.demo5 .gallery__item-link5 {
	/* background: #d75828;
	color:#fff; */
	/* margin: 0px 0px 150px 200px; */

	font-size: 20px;
	font-family: "Courier Prime";
	width: 100px;
	height: 100px;
	border-radius: 50%;



	/* font-size: 1.5rem; */
	text-decoration: underline;
	cursor: pointer;

	grid-area: gallery-link;
	align-self: end;
	display: flex;
	position: relative;
	padding: 1.5rem;
	overflow: hidden;
	justify-content: center;
	align-items: center;

	transition: 0.2s transform ease-in-out, 0.6s background-color;
	will-change: transform;
	z-index: 0;
}
/* .demo5 .gallery__item-link5:hover {
	color: #fff;
	background-color:  #d4b77d;

} */
/* .demo5 .gallery__item-link5::after {
	background-color:  #d4b77d;
} */
/* .demo5 .gallery__item-link {
	grid-area: gallery-link;
	align-self: end;
	font-size: 1.5rem;
	background: var(--color-gallery-link-bg);
	color: var(--color-gallery-link);
	text-decoration: underline;
	width: 120px;
	height: 120px;
	border-radius: 50%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.demo5 .gallery__item-link:focus,
.demo5 .gallery__item-link:hover {
	background: var(--color-gallery-link-bg-hover);
	color: var(--color-gallery-link-hover);
	text-decoration: none;
} */

.demo5 .gallery__item-tags5 {
	grid-area: gallery-tags;
	justify-self: end;
	font-size: 20px;
	display: grid;
	grid-auto-flow: column;
	grid-gap: 1rem;
	cursor: pointer;
	margin: -130px 0px 0px 220px;
	cursor: pointer;
	font-family: "Courier Prime";
	font-weight: lighter;
	color: black;
}
.demo5 .gallery__item-tags5:hover{
	color:#463832;
}

.demo5 .gallery__text {
	font-size: 12vw;
	line-height: 0.8;
	margin: 0 10vw 0 14vw;
	text-transform: lowercase;
	color: #463832;
	color: transparent;
	-webkit-text-stroke: 1px #463832;
	text-stroke: 1px #463832;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
}

.demo5 .gallery__text-inner {
	display: block;
}