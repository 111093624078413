/* body:has(.demo2){
	--color-text: #66665d;
	--color-bg: #0e0e0d;
	--color-link: #cc0000;
	--color-link-hover: #fff;
	--color-gallery-title: #d9d0be;
	--color-gallery-number: #181817;
	--color-gallery-link: #fff;
	--color-gallery-link-bg: #cc0000;
	--color-gallery-link-hover: #0e0e0d;
	--color-gallery-link-bg-hover: #d9d0be;
	--cursor-stroke: none;
	--cursor-fill: #cc0000;
	--cursor-stroke-width: 1px;
	
} */
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200..900&display=swap");
.demo2 .rotate {
	transform: rotate(-6deg);
}

.demo2 .gallery2 {
	display: flex;
	gap: 40px;
	padding-left: 12vw;
	margin: 3vw 5vw 0 0;
	
}

.demo2 .gallery__item2 {
	margin: 0 3vw;
	display: grid;
	/* grid-template-columns: max-content; */
	grid-template-areas: '... ...' 
						 '... gallery-image'
						 '... ...' ;
	grid-template-columns: 8rem 21vmax;
	grid-template-rows: 6rem 28vmax 3rem;
	
	
	
;
}

.demo2 .gallery__item-img {
	grid-area: gallery-image;
	/* width: 40vw; */
	width: 30vw;
	height: 40vh;
	/* height: 100%; */
	/* overflow: hidden; */
	position: relative;
	will-change: transform;
	transition: 0.15s;

	
	
}

.demo2 .gallery__item-imginner {
	background-size: cover;
    background-position: 50% 0;
	/* width: calc(100% + 10vw); */
	/* margin-left: -5vw; */
    /* height: 100%; */
    will-change: transform;
	

	

}

.demo2 .gallery__item-caption {
	grid-area: 1 / 1 / 4 / 3;
	display: grid;
	grid-template-areas: 'gallery-number gallery-title' 
						 'gallery-link ...'
						 'gallery-link gallery-tags' ;
	grid-template-columns: 8rem auto;
	grid-template-rows: 8rem auto 3rem;
	/* font-family: "Courier Prime"; */
  

}

.demo2 .gallery__item-number {
	grid-area: gallery-number;
	font-size: 6rem;
	font-size: clamp(2.5rem,9vw,6.5rem);
	justify-self: end;
	padding-right: 2rem;
	color: #181817;
}

.demo2 .gallery__item-title2 {
	grid-area: gallery-title;
	margin: 0;
	font-size: 42px;
	/* font-size: clamp(2rem,3vw,4rem); */
	color: #d9d0be;
	font-family: "Courier Prime";
	align-self: center;

}

.demo2 .gallery__item-number,

.demo2 .gallery__text {
	font-family: moret, serif;
	font-weight: 800;
	/* font-style: italic; */
	align-self: center;
}

.demo2 .gallery__item-link2 {
	/* background: #cc0000; */
	/* color: #fff; */
	/* margin: 0px 0px 150px 120px; */
	font-size: 20px;
	font-family: "Courier Prime";
	width: 100px;
	height: 100px;
	border-radius: 50%;



	/* font-size: 1.5rem; */
	text-decoration: underline;
	cursor: pointer;

	grid-area: gallery-link;
	align-self: end;
	display: flex;
	position: relative;
	padding: 1.5rem;
	overflow: hidden;
	justify-content: center;
	align-items: center;

	transition: 0.2s transform ease-in-out, 0.6s background-color;
	will-change: transform;
	z-index: 0;

	
}
/* .demo2 .gallery__item-link2:hover {
	color: #0e0e0d;
	background-color:  #d9d0be;

} */
/* .demo2 .gallery__item-link2::after {
	background-color:  #d9d0be;
} */

.demo2 .gallery__item-tags2 {
	grid-area: gallery-tags;
	justify-self: end;
	font-size: 20px;
	display: grid;
	grid-auto-flow: column;
	grid-gap: 1rem;
	margin: -130px -60px 0px 0px;
	cursor: pointer;
	font-family: "Courier Prime";
	font-weight: lighter;
	color: #0e0e0d;

}

.demo2 .gallery__text {
	font-size: 20vw;
	line-height: 0.8;
	margin: 0 12vw;
	text-transform: lowercase;
	color: transparent;
	-webkit-text-stroke: 1px #645c5b;
	/* text-stroke: 1px #645c5b;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent; */
	font-family: 'Courier New', Courier, monospace;
}

.demo2 .gallery__text-inner {
	display: block;
}

@media screen and (min-width: 53em) {
	.demo2 .frame {
		grid-template-areas: 'title demos demos links';
	}
	.demo2 .frame__info {
		justify-self: end;
	}
}
