/* 

.product-swiper, .swiper {
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
 
  height: 580px;
  width: 563px;
  padding: 12px 0 0px 0;
  background-color: white;
  margin: 0px 0px 30px 0px;
  border-radius: 14px;
} */
/* .product-swiper, .swiper-slide{
  background: white;
  max-width: 563px;
 max-height: 618px;
  overflow: hidden;
  height: 618px;
  
  border-radius: 14px;

} */




/* .product-swiper, .swiper-slide{
  background: white;
  width: 563px;
  height: 648px;
  overflow: hidden;
} */

/* Swiper button styles */
.product-swiper .swiper-button-prev,
.product-swiper .swiper-button-next {
  width: 11px; 
  height: 27px; 
  padding: 30px 0 25px 0;
}

.product-swiper .swiper-button-prev::after,
.product-swiper .swiper-button-next::after {
  font-size: 18px;
  color: #888888;
}

/* Pagination styles */
.product-swiper .swiper-pagination {
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  
}

.product-swiper .swiper-pagination-fraction, 
.product-swiper .swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets, 
.swiper-pagination-bullets.swiper-pagination-horizontal {
  top: 560px; 
  left: 0;
  width: 100%;
  
}

/* Default width for info toast */
.Toastify__toast--info {
  width: 80%; 
  max-width: 400px; 
  /* margin: 0 auto;  */
}


.Toastify__toast--success {
  width: 80%; 
  max-width: 400px; 
  margin: 0 auto; 
}

/* For screens larger than 768px (tablets and desktops) */
@media (min-width: 768px) {
  .Toastify__toast--info {
    width: 370px !important; /* Custom width for tablets and larger screens */
  }
  .Toastify__toast--success {
    width: 400px !important; /* Custom width for tablets and larger screens */
  }
}

/* For screens smaller than 768px (mobile devices) */
@media (max-width: 767px) {
  .Toastify__toast--info {
    width: 100% !important; /* Custom width for mobile devices */
   margin: auto;
 
 
  }
  .Toastify__toast--success{
     width: 100% !important; /* Custom width for mobile devices */
    }
}



@media (max-width: 1024px) {
  .product-swiper .swiper-pagination-fraction, 
  .product-swiper .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets, 
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    top: 500px; 
  }
}

@media (max-width: 768px) {
  .product-swiper .swiper-pagination-fraction, 
  .product-swiper .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets, 
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    top: 540px; 
    width: 100%;
    left: 0;
  }
}

@media only screen 
  and (min-width: 768px) 
  and (max-width: 768px) 
  {
    .product-swiper .swiper-pagination-fraction, 
    .product-swiper .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets, 
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      top: 700px; 
      width: 100%;
      left: 0;
    }

  }




@media (max-width: 480px) {
  .product-swiper .swiper-pagination-fraction, 
  .product-swiper .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets, 
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    top:310px;
    width: 100%;
    left: 0;
  }
}

.product-swiper .swiper-pagination-bullet-active {
  opacity: 1;
  background-color: black;
}


.related-products-swiper {
  height: 100%;
  padding: 0;
}

/* Responsive adjustments */
/* @media (max-width: 1200px) {
  

  .product-swiper .swiper-button-prev, 
  .product-swiper .swiper-button-next {
    padding: 20px 0 15px 0;
  }

  .product-swiper .swiper-button-prev::after,
  .product-swiper .swiper-button-next::after {
    font-size: 16px; 
  }
}

@media (max-width: 768px) {
  .product-swiper, .swiper {
    height: 350px; 
  }

  .product-swiper .swiper-button-prev, 
  .product-swiper .swiper-button-next {
    padding: 15px 0 10px 0; 
  }

  .product-swiper .swiper-button-prev::after,
  .product-swiper .swiper-button-next::after {
    font-size: 14px; 
  }

  .product-swiper .swiper-pagination {
    top: 450px; 
  }
}

@media (max-width: 480px) {
  .product-swiper, .swiper {
    height: 450px;
    padding: 0px 0px 0px 0px; 
    margin: 0px 0px 0px 0px;
    
  }

  .product-swiper .swiper-button-prev, 
  .product-swiper .swiper-button-next {
    padding: 10px 0 5px 0;
  }

  .product-swiper .swiper-button-prev::after,
  .product-swiper .swiper-button-next::after {
    font-size: 12px; 
  }

  .product-swiper .swiper-pagination {
    top: 400px; 
  }
} */




/* .product-swiper .swiper-button-prev,
.product-swiper .swiper-button-next {
  width: 11px; 
  height: 27px; 
  padding: 30px 0px 25px 0px;
}

.product-swiper .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    top: 530px;
    left: 0;
    width: 100%;
    
}
.product-swiper,.swiper{
  
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
    height:560px;
    width: 100%;
    padding: 20px 0px 0px 0px;
 

}

.product-swiper .swiper-button-prev::after,
.product-swiper .swiper-button-next::after {
  font-size: 18px;
  color: #888888;

}

.product-swiper .swiper-pagination {
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.product-swiper .swiper-pagination-bullet-active {
  opacity: 1;
  background-color: black;
} */


.related-products-swiper{
    height: 100%;
   padding: 0px;
   background-color: transparent;
}
.related-products-swiper, .swiper-slide{
  background-color: transparent;
  height: 100%;
  max-width: 100%;
  border-radius: 0%;
}
/* .related-products-swiper .swiper-button-prev,
.related-products-swiper .swiper-button-next {
  width: 15px;  
  height: 30px; 
  padding: 20px 0px 0px 0px;
}
.related-products-swiper .swiper-button-prev{
    padding: 0px 1200px 0px 0px;
}

.related-products-swiper .swiper-button-prev::after,
.related-products-swiper .swiper-button-next::after {
  font-size: 30px; 
  color: #555555;

}

.related-products-swiper .swiper-pagination {
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  
  
}

.related-products-swiper .swiper-pagination-bullet-active {
  opacity: 1;
  background-color: black; 
} */



.related-products-swiper .swiper-button-prev::after {
    content: ''; 
    font-family: 'Font Awesome 5 Free'; 
    font-weight: 900;
    font-size: 24px; 
    color: #888888; 

    display: inline-block; 
    padding: 10px; 
    border: 2px solid #888888;
    border-radius: 50%; 
    
   
    background-color: #f5f5f5; 
    
   
    text-align: center;
    margin: 0px 20px 0px 0px;

  }
  
  .related-products-swiper .swiper-button-next::after {
    content: '';
    font-family: 'Font Awesome 5 Free'; 
    font-weight: 900; 
    font-size: 24px; 
    color: #888888;
    margin: 0px 0px 0px 20px;
    
    display: inline-block; 
    padding: 10px; 
    border: 2px solid #888888;
    border-radius: 50%;
    
   
    background-color: #f5f5f5;
    

    text-align: center;
  }
  


.custom-prev, .custom-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    
   
    
   
    
  }
  
  
  .custom-prev {
    left: -25px; 
   
   
  }
  
  .custom-next {
    right: 31px; 
  }
  