.Light_Gray{
    background-color: #E9E9E9;
  }
  .Light_Gray_1{
    background-color: #E9E9E9;

  }
  .Dark_Purple_Gradient{
    background-image: linear-gradient(288.21deg, #412653 4.1%, #3F576F 99.58%);
  }
  .Charcoals{
    background-color: #1D181F;
  }
  .Charcoal_1{
    background-color: #1D181F;
    
    
  }
  .dark_shade_gary{
    background-color:#2C2B30;
    
  }
  .Cream{
    background-color: #FFFBEF;
  }
  .Soft_Gray{
    background-color: #EFEFEF;
  }
  .Muted_Beige{
    background-color: #E2D8D0;
  }
  .black_1{
    background-color: #121212;

  }
  .control{
    background-color: #F2F2F3;

    
  
  }
