@tailwind base;
@tailwind components;
@tailwind utilities;






.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  

  .hide-scrollbar {
    scrollbar-width: none;
  }


  .Toastify__toast--info {
    width: 400px !important;
  }