*,
*::after,
*::before {
	box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200..900&display=swap");



 
.demo1 .gallery__item-img, 
.demo4 .gallery__item-img, 
.demo5 .gallery__item-img{
	scale: 0.92 !important;
}
.demo2 .gallery__item-img{
	transition: 0.15s;
	
}

.demo3 .gallery__item-img{
	transition: 0.2s scale ease-out;
}
.demo3 .gallery__item-img:hover{
	scale: 1.1;
	transition: 0.2s scale ease-in;
}


.gallery__item-link {
	width: 120px;
	height: 120px;
	border-radius: 50%;

	font-family: roboto;
	font-size: 1.5rem;
	text-decoration: underline;
	cursor: pointer;

	grid-area: gallery-link;
	align-self: end;
	display: flex;
	position: relative;
	padding: 1rem 2rem;
	overflow: hidden;
	justify-content: center;
	align-items: center;

	transition: 0.2s transform ease-in-out, 0.6s background-color;
	will-change: transform;
	z-index: 0;
}
.gallery__item-link::after {
	border-radius: 3rem;
	content: '';
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	transform: translate(-100%, 0) rotate(10deg);
	transform-origin: top left;
	transition: 0.2s transform ease-out;
	will-change: transform;
	z-index: -1;
}
.gallery__item-link:hover::after {
	transform: translate(0, 0);
}
.gallery__item-link:focus,
.gallery__item-link:hover {
	transform: scale(1.05);
	will-change: transform;
	text-decoration: none;
	background-color: #d4b77d !important;
}


.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
}

a:hover,
a:focus {

	outline: none;
}

.frame {
	padding: 3rem 5vw;
	text-align: center;
	position: relative;
	z-index: 1000;
	text-transform: uppercase;
}

.frame__title {
	font-size: 1rem;
	margin: 0 0 1rem;
	font-weight: 300;
}

.frame__links {
	display: inline;
}

.frame__links a:not(:last-child) {
	margin-right: 1rem;
}

.frame__demo {
	margin: 0 1rem;
}

.frame__demo--current,
.frame__demo--current:hover {
	color: var(--color-text);
	cursor: default;
}

.contents {
	display: flex;
	flex-direction: column;

	position: relative;
	justify-content: flex-start;
}
.contentDemo2 {
	display: flex;
	flex-direction: column;
height: auto;
	position: relative;
	justify-content: flex-start;
	
}


.contentsDemo3 {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 13rem);
	position: relative;
	justify-content: flex-start;
}

.contentsDemo4 {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 13rem);

	/* height: 100vh; */
	padding: 10px 0px 0px 0px;
	/* position: relative; */
	
	
	justify-content: center;
	overflow: hidden;
}
.contentsDemo5 {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 13rem);
	/* max-height: 50vh; */
    overflow: hidden;
	position: relative;
	justify-content: center;
}


.cursor {
	display: none;
}

@media screen and (min-width: 53em) {
	.frame {
		position: fixed;
		text-align: left;
		z-index: 100;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		width: 100%;
		max-width: none;
		padding: 1.75rem;
		pointer-events: none;
		grid-template-columns: auto 1fr 1fr auto;
		grid-template-rows: auto;
		grid-template-areas: 'title demos demos links';
	}

	.frame__title {
		margin: 0 4rem 0 0;
		grid-area: title;
	}

	.frame__info {
		grid-area: info;
	}

	.frame__demos {
		grid-area: demos;
	}

	.frame__links {
		grid-area: links;
		padding: 0;
		justify-self: end;
	}

	.frame a {
		pointer-events: auto;
	}

	.contents {

		justify-content: center;
		max-height: 85vh;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.contentsDemo3 {
		height: 80vh;
		justify-content: center;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.contentDemo2 {
		max-height: 100vh;
		justify-content: center;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.contentsDemo4 {
		height: 100vh;
		/* max-height: fit-content; */
		justify-content: center;
		/* overflow: hidden ;  */
		/* overflow: hidden; */
	
		
	}
	.contentsDemo5 {
		height: 100vh;
		/* max-height: fit-content; */
		justify-content: center;
		/* overflow-y: scroll; */
		/* overflow-x: hidden; */
	}
}

@media (any-pointer:fine) {
	.cursor {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		pointer-events: none;
	}

	.cursor__inner {
		fill: var(--cursor-fill);
		stroke: var(--cursor-stroke);
		stroke-width: var(--cursor-stroke-width);
		opacity: 0.7;
	}

	.no-js .cursor {
		display: none;
	}

}

/* html.has-scroll-smooth {

	overflow-y:hidden ;
	

	
} */

html.has-scroll-dragging {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* .has-scroll-smooth body {
	overflow: auto;
} */

[data-scroll-container] {
    /* overflow-x: hidden; 
    overflow-y: hidden;  */
    white-space: nowrap; /* Prevent wrapping */
    display: flex; /* Ensure horizontal layout */
	justify-content: center;

}


.has-scroll-smooth [data-scroll-container] {
	max-height: 100vh;
	/* display: flex;
	justify-content: center; */
	
	
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
	white-space: nowrap;

	display: inline-block;
	
	white-space: nowrap;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
	display: inline-block;
	vertical-align: top;
	white-space: nowrap;
	height: 100%;
}

.c-scrollbar {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 11px;
	/* height: 100%; */
	transform-origin: center right;
	transition: transform 0.3s, opacity 0.3s;
	opacity: 0;
}

.c-scrollbar:hover {
	transform: scaleX(1.45);
}

.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
	opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
	width: 100%;
	height: 10px;
	top: auto;
	bottom: 0;
	transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
	transform: scaleY(1.3);
}

.c-scrollbar_thumb {
	position: absolute;
	top: 0;
	right: 0;
	background-color: black;
	opacity: 0.5;
	width: 7px;
	border-radius: 10px;
	margin: 2px;
	cursor: -webkit-grab;
	cursor: grab;
}

.has-scroll-dragging .c-scrollbar_thumb {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
	right: auto;
	bottom: 0;
}